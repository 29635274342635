import { NuxtAxiosInstance } from "@nuxtjs/axios"
import { NuxtRuntimeConfig } from "@nuxt/types/config/runtime"
import VueI18n from "vue-i18n"
import {
  CATEGORY_V4_ID_FURNITURE,
  CATEGORY_V4_ID_HOUSE_MOVE,
  CATEGORY_V4_ID_CAR,
  CATEGORY_V4_ID_MOTORCYCLE,
  CATEGORY_V4_ID_PIANO,
  CATEGORIES
} from "~/constants/category"
import {
  FLAT,
  HOUSE,
  LIFT_AVAILABLE,
  LIFT_UNAVAILABLE,
  PROPERTY_TYPE_LIST_FLAT,
  PROPERTY_TYPE_LIST_HOUSE,
  PROPERTY_TYPE_LIST_WITH_FLOOR
} from "~/constants/listing"
import { shouldUseV2 } from "~/utils/v4-redirect"
import { getv4LocaleFromI18nLocale } from "~/scripts/useful-functions"

const apiUrl = {
  preListing: {
    post: "ng/prelisting"
  },
  email: {
    post: "ng/retrieve-quote"
  }
}

export const v4Url = {
  preListingWithLocation: {
    furniture: "instant-prices/furniture/listing-furniture"
  },
  preListingWithLocationAndItems: {
    furniture: "instant-prices/furniture/listing-price-options"
  },
  preListingWithLocationAndProperty: {
    houseMove: "instant-prices/house-move/listing-inventory"
  },
  preListingWithLocationAndPropertyAndItems: {
    houseMove: "instant-prices/house-move/listing-prices"
  },
  preListing: {
    furniture: "instant-prices/furniture/listing-items",
    houseMove: "instant-prices/house-move/listing-items",
    car: "instant-prices/car/listing-items",
    motorCycle: "instant-prices/motorcycle/listing-items",
    piano: "instant-prices/piano/listing-items"
  },
  international: {
    furniture: "send-goods/general_goods_move",
    houseMove: "send-goods/house_move",
    car: "send-goods/vehicle_move",
    motorCycle: "send-goods/motorcycles",
    piano: "send-goods/piano"
  },
  other: "categories",
  internationalPostfix: "?force_send_goods=1"
}

export function getV4Url(
  v4Host: string,
  responseData: ListingResponseData,
  payload: ListingPayload,
  locale = "en"
) {
  const categoryId = responseData?.category_id
  let preListingUrl = ""
  if (responseData?.from && responseData?.to) {
    const localePathSeparator = locale === "en" ? "/" : `/${locale}/`
    if (categoryId === CATEGORY_V4_ID_FURNITURE) {
      if (responseData?.move_items?.length && payload.move_items?.length) {
        return v4Host + localePathSeparator + v4Url.preListingWithLocationAndItems.furniture
      }
      return v4Host + "/" + v4Url.preListingWithLocation.furniture
    }
    if (
      categoryId === CATEGORY_V4_ID_HOUSE_MOVE &&
      responseData?.origin_type_of_property &&
      responseData?.destination_type_of_property
    ) {
      /*
      TODO When houseMove Item picker will be ready uncomment and make sure to provide volume validation:
      - volume >= 8m3 should be redirected to houseMove
      - volume < 8m3 should be redirected to furniture
      if (responseData?.move_items?.length && payload.move_items?.length) {
        return v4Host + "/" + v4Url.preListingWithLocationAndPropertyAndItems.houseMove
      }
      */
      return v4Host + localePathSeparator + v4Url.preListingWithLocationAndProperty.houseMove
    }
    preListingUrl = responseData?.pre_listing_url
  }

  if (preListingUrl) {
    switch (categoryId) {
      case CATEGORY_V4_ID_CAR:
      case CATEGORY_V4_ID_MOTORCYCLE:
      case CATEGORY_V4_ID_PIANO:
        return preListingUrl
    }
  }

  return v4Host + "/" + getV4UrlByCategoryId(categoryId)
}

export function getV4UrlByCategoryId(categoryId: number = 0) {
  switch (categoryId) {
    case CATEGORY_V4_ID_FURNITURE:
      return v4Url.preListing.furniture
    case CATEGORY_V4_ID_HOUSE_MOVE:
      return v4Url.preListing.houseMove
    case CATEGORY_V4_ID_CAR:
      return v4Url.preListing.car
    case CATEGORY_V4_ID_MOTORCYCLE:
      return v4Url.preListing.motorCycle
    case CATEGORY_V4_ID_PIANO:
      return v4Url.preListing.piano
    default:
      return v4Url.other
  }
}

export function getV2UrlByCategoryId(categoryId: number = 0) {
  switch (categoryId) {
    case CATEGORY_V4_ID_FURNITURE:
      return v4Url.international.furniture + v4Url.internationalPostfix
    case CATEGORY_V4_ID_HOUSE_MOVE:
      return v4Url.international.houseMove
    case CATEGORY_V4_ID_CAR:
      return v4Url.international.car + v4Url.internationalPostfix
    case CATEGORY_V4_ID_MOTORCYCLE:
      return v4Url.international.motorCycle + v4Url.internationalPostfix
    case CATEGORY_V4_ID_PIANO:
      return v4Url.international.piano + v4Url.internationalPostfix
    default:
      return v4Url.other
  }
}

export function getV4ListingPayload(
  listing: Listing | null,
  categoryV4Id: number,
  pageLocale: VueI18n.Locale
) {
  const payload: ListingPayload = {
    category_id: categoryV4Id,
    v: 4,
    stage: 0,
    toStage: 1,
    is_instant_pricing: true
  }
  if (listing) {
    if (
      listing.pickup.address &&
      listing.pickup.address?.coordinates.lat &&
      listing.pickup.address?.coordinates.lng
    ) {
      payload.from = listing.pickup.address?.place?.formatted_address
      payload.pickup_address_data = {
        lookup: "",
        country: null,
        postcode: listing.pickup.address?.postcode,
        ISO_3166_1_Alpha_2: listing.pickup.address?.country,
        town: listing.pickup.address?.city,
        latitude: listing.pickup.address?.coordinates.lat,
        longitude: listing.pickup.address?.coordinates.lng
      }
      if (listing.pickup.address?.place?.place_id) {
        payload.pickup_address_data.lookup = "places/" + listing.pickup.address?.place?.place_id
      }
      const pickupComponents = listing.pickup.address?.place?.address_components
      if (
        listing.pickup.address?.country !== "GB" &&
        listing.pickup.address?.country !== "ES" &&
        pickupComponents &&
        pickupComponents[pickupComponents.length - 1].types[0] === "country"
      ) {
        payload.pickup_address_data.country =
          pickupComponents[pickupComponents.length - 1].long_name
      }
    }
    if (
      listing.delivery.address &&
      listing.delivery.address?.coordinates.lat &&
      listing.delivery.address?.coordinates.lng
    ) {
      payload.to = listing.delivery.address?.place?.formatted_address
      payload.delivery_address_data = {
        lookup: "",
        country: null,
        postcode: listing.delivery.address?.postcode,
        ISO_3166_1_Alpha_2: listing.delivery.address?.country,
        town: listing.delivery.address?.city,
        latitude: listing.delivery.address?.coordinates.lat,
        longitude: listing.delivery.address?.coordinates.lng
      }
      if (listing.delivery.address?.place?.place_id) {
        payload.delivery_address_data.lookup = "places/" + listing.delivery.address?.place?.place_id
      }
      const deliveryComponents = listing.delivery.address?.place?.address_components
      if (
        listing.delivery.address?.country !== "GB" &&
        listing.delivery.address?.country !== "ES" &&
        deliveryComponents &&
        deliveryComponents[deliveryComponents.length - 1].types[0] === "country"
      ) {
        payload.delivery_address_data.country =
          deliveryComponents[deliveryComponents.length - 1].long_name
      }
    }

    if (isValidPropertyType(listing.pickup.propertyType)) {
      payload.origin_type_of_property = listing.pickup.propertyType
      if (listing.pickup.floor) {
        payload.origin_property_level = listing.pickup.floor
      }
      if (listing.pickup.liftAvailable !== null) {
        if (listing.pickup.liftAvailable === LIFT_UNAVAILABLE) {
          payload.origin_property_elevator = null
        }
        if (listing.pickup.liftAvailable === LIFT_AVAILABLE) {
          payload.origin_property_elevator = listing.pickup.liftAvailable
        }
      }
    }
    if (isValidPropertyType(listing.delivery.propertyType)) {
      payload.destination_type_of_property = listing.delivery.propertyType
      if (listing.delivery.floor) {
        payload.destination_property_level = listing.delivery.floor
      }
      if (listing.delivery.liftAvailable !== null) {
        if (listing.delivery.liftAvailable === LIFT_UNAVAILABLE) {
          payload.destination_property_elevator = null
        }
        if (listing.delivery.liftAvailable === LIFT_AVAILABLE) {
          payload.destination_property_elevator = listing.delivery.liftAvailable
        }
      }
    }

    if (payload.category_id === CATEGORY_V4_ID_HOUSE_MOVE) {
      payload.stage = 1
    }

    if (payload?.from && payload?.to) {
      payload.stage = 1

      if (payload.category_id === CATEGORY_V4_ID_HOUSE_MOVE) {
        payload.toStage = 2
      }
    }

    payload.move_items = listing.items
    if (payload.move_items && payload.move_items.length) {
      payload.stage = 2
      payload.toStage = 3
    }
  }

  if (shouldUseV2(payload, pageLocale)) {
    payload.v = 2
    payload.is_instant_pricing = false
  }

  return payload
}

// TODO Use isValidLocation in getV4ListingPayload as well
export function isValidLocation(location) {
  return location.address && location.address?.coordinates.lat && location.address?.coordinates.lng
}

export function isValidPropertyType(propertyType) {
  return propertyType && propertyType !== HOUSE && propertyType !== FLAT
}

export function isHouse(propertyType) {
  return (
    propertyType === HOUSE ||
    (propertyType !== "" && PROPERTY_TYPE_LIST_HOUSE.find(option => option.value === propertyType))
  )
}

export function isFlat(propertyType) {
  return (
    propertyType === FLAT ||
    (propertyType !== "" && PROPERTY_TYPE_LIST_FLAT.find(option => option.value === propertyType))
  )
}

export function requiresFloor(propertyType) {
  return (
    propertyType !== "" &&
    PROPERTY_TYPE_LIST_WITH_FLOOR.find(option => option.value === propertyType)
  )
}

export function addPreListingIdToSessionStorage(responseData) {
  if (typeof Storage !== "undefined") {
    if (
      responseData.category_id === CATEGORY_V4_ID_HOUSE_MOVE ||
      (responseData.from && responseData.to)
    ) {
      const preListingId = responseData.pre_listing_id
      const now = new Date().getTime()
      const item = {
        key: "preListingId",
        value: preListingId,
        created: now,
        accessed: now
      }
      sessionStorage.setItem("anyVan.defSessionCache.data.preListingId", JSON.stringify(item))
      sessionStorage.setItem("anyVan.defSessionCache.keys", JSON.stringify(["preListingId"]))
    } else if (!responseData.from || !responseData.to) {
      sessionStorage.removeItem("anyVan.defSessionCache.data.preListingId")
      sessionStorage.removeItem("anyVan.defSessionCache.keys")
    }
  }
}

export function getCategoryById(id: string): Category | null {
  return CATEGORIES.find(category => category.id === id) || null
}
export function getCategoryByV4Id(v4Id: number): Category | null {
  return CATEGORIES.find(category => category.v4Id === v4Id) || null
}

export function getCategoryIcon(icon: string | undefined) {
  return icon ? ["custom", icon, "", "", "#292929"] : ["custom", ""]
}

export class ListingService {
  constructor(private axios: NuxtAxiosInstance, private config: NuxtRuntimeConfig) {}

  async postPreListing(payload: object, locale = "en"): Promise<any | null> {
    const serviceUrl = this.config.v4Host + "/" + apiUrl.preListing.post

    try {
      const config = {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "AV-locale": getv4LocaleFromI18nLocale(locale)
        },
        withCredentials: true
      }
      const response = await this.axios.post(serviceUrl, payload, config)
      if (response.status === 200) {
        return response
      }
    } catch (err) {
      return null
    }
  }

  async postEmail(payload: object): Promise<any | null> {
    const serviceUrl = this.config.v4Host + "/" + apiUrl.email.post

    try {
      const response = await this.axios.post(serviceUrl, payload, {
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        },
        withCredentials: true
      })
      if (response.status === 200) {
        return response
      }
    } catch (err) {
      return null
    }
  }

  getCategoriesLink() {
    return this.config.v4Host + "/" + v4Url.other
  }
}
